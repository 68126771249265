const dev = {
  setting: {
    URL:
      process.env.REACT_APP_LOCALHOST === "1"
        ? "http://localhost"
        : "https://dev.api.krishi.network",
    debug: true,
  },
};

const prod = {
  setting: {
    URL: "https://thekrishi.com/v2",
    // URL: "https://api.krishi.network",
    debug: false,
  },
};
const config = process.env.REACT_APP_STAGE === "production" ? prod : dev;
export default config;

// log dev url and prod url
console.log(config.setting.URL);
