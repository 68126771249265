import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
// import { foo } from "./Components/firebase";
import * as serviceWorker from "./serviceWorker";
import config from './config';
global.config = config;

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
