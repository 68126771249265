import React, { useEffect } from "react";
import { useState } from "react";
import { withRouter } from "react-router-dom";
import Util from "../../utils/code_utils";
import "../css/carousel.css";
import CarouselCard from "./CarouselCard";
import FishFarming from "../../assets/FishFarming.png";
import GoatFarming from "../../assets/GoatFarming.png";
import Vermicomposting from "../../assets/Vermicomposting.png";
import MushroomFarming from "../../assets/MushroomFarming.png";
import OrganicFarming from "../../assets/OrganicFarming.png";
import HenFarming from "../../assets/HenFarming.png";
import Hydroponics from "../../assets/Hydroponics.png";
import StorageUtil from "../../utils/storage_util";
let carouseWebviewCSS;
function AdCarousel(props) {
  const [carouselTitle, setCarouselTitle] = useState(
    "कृषि सफल - प्रशिक्षण से प्रगति तक"
  );
  const [carouselProducts, setCarouselProducts] = useState([]);

  const handleCardClick = (url, id, title, tutorName) => {
    let base_url = window.location.origin;
    if (!base_url) {
      base_url = "https://krishi.network";
    }
    var params = StorageUtil.getUserProperties();
    if (!params) {
      let name = localStorage.getItem("name") || "";
      let number = localStorage.getItem("number") || "";
      params = "&name=" + name + "&number=" + number;
    }
    console.log(params);
    params
      ? Util.handleOpenURL(base_url + url + params, id, title, tutorName)
      : Util.handleOpenURL(base_url + url, id, title, tutorName);
    // Util.handleOpenURL(base_url + url + params, id, title, tutorName);
  };

  useEffect(() => {
    // make api calls for title and products
    // this is the array for banners
    carouseWebviewCSS = {
      "--carousel-card-width": "210px",
      "--carousel-card-height": "196px",
    };
    let temp = [
      {
        name: "बकरी पालन",
        url: "/landingpage?pid=KJIX14&lang=hi",
        img: GoatFarming,
        id: "KJIX14",
        courseName: "बकरी पालन",
        tutorName: "दीपक पाटीदार",
      },
      {
        name: "बायोफ्लॉक मछली पालन",
        url: "/landingpage?pid=0NVPHT&lang=hi",
        img: FishFarming,
        id: "0NVPHT",
        courseName: "बायोफ्लॉक मछली पालन",
        tutorName: "अभिनव डोबरियाल",
      },
      {
        name: "वर्मीकम्पोस्टिंग",
        url: "/landingpage?pid=GFZWPG&lang=hi",
        img: Vermicomposting,
        id: "GFZWPG",
        courseName: "वर्मीकम्पोस्टिंग",
        tutorName: "संतोष कुमार तिवारी",
      },
      {
        name: "मशरूम की खेती",
        url: "/landingpage?pid=NDCA4O&lang=hi",
        img: MushroomFarming,
        id: "NDCA4O",
        courseName: "मशरूम की खेती",
        tutorName: "अमित प्रसाद",
      },
      {
        name: "जैविक खेती",
        url: "/landingpage?pid=SG9DC7&lang=hi",
        img: OrganicFarming,
        id: "SG9DC7",
        courseName: "जैविक खेती",
        tutorName: "श्री एस.के. सिंह",
      },
      {
        name: "-",
        url: "/landingpage?pid=S7OMVL&lang=hi",
        img: HenFarming,
        id: "S7OMVL",
        courseName: "-",
        tutorName: "-",
      },
      {
        name: "-",
        url: "/landingpage?pid=OZ8G2J&lang=hi",
        img: Hydroponics,
        id: "OZ8G2J",
        courseName: "-",
        tutorName: "-",
      },
    ];
    setCarouselProducts(temp);
    // setCarouselTitle("आय 💵 बढ़ाने के लिए घर बैठे प्रशिक्षण करें");
    return () => {};
  }, []);

  return (
    <div className="social_feed_main">
      <div className="carousel_webview" style={carouseWebviewCSS}>
        <h1 className="carousel_title">{carouselTitle}</h1>
        <div className="carousel">
          {carouselProducts.map((product) => (
            <CarouselCard product={product} handleCardClick={handleCardClick} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default withRouter(AdCarousel);
