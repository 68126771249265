import { firebaseConfig } from "../Components/firebase";
import { initializeApp } from "firebase/app";
import { getAnalytics, setUserProperties, logEvent } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import Util from "./code_utils";

export class FirebaseUtility {
  // todo can set user properties

  static init() {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    // analytics instance
    const analytics = getAnalytics(app);
    // Initialize Performance Monitoring and get a reference to the service
    const perf = getPerformance(app);
  }

  static logEvent(eventName, params) {
    const analytics = getAnalytics();
    if (process.env.REACT_APP_STAGE !== "production") {
      console.log(eventName, params);
    } else {
      logEvent(analytics, eventName, params);
    }
  }

  static setUserProperties(properties) {
    const analytics = getAnalytics();
    if (!Util.isNullAndEmpty(properties)) {
      setUserProperties(analytics, properties);
    }
    // logEvent(analytics, eventName, params);
  }
}
