import { APP_CONSTANTS } from './constant';
import Util from './code_utils';

export default class StorageUtil {

    static setQueryParams(params) {
        if (!Util.isNullAndEmpty(params)) {
            // Util.showToast(params);
            // console.log('params',params,typeof params);
            if (params.includes("?")) {
                params = params.split("?")[1];
            }
            // TODO add langugae code here.
            let paramsObj = new URLSearchParams(params);
            if(paramsObj.get("lang") !== "en")
            {
                paramsObj.set('lang',APP_CONSTANTS.default_products_lang);
            }
            params = paramsObj.toString();
            localStorage.setItem(APP_CONSTANTS.params, params);
            StorageUtil.setUserProperties(params);

        }
    }

    static setUserProperties(params)
    {
        if (!Util.isNullAndEmpty(params)) {
            if (params.includes("?")) {
                params = params.split("?")[1];
            }
            let paramsObj = new URLSearchParams(params);
            let user_properties = {
                "name": paramsObj.get("name"),
                "number": paramsObj.get("number"),
                "current_lang": paramsObj.get("lang"),
                "user_id": paramsObj.get("user_id"),
                "appinstance_id": paramsObj.get("appinstance_id"),
            }
            sessionStorage.setItem(APP_CONSTANTS.user_properties,JSON.stringify(user_properties))
        }
    }

    static getUserProperties(){
        let value = sessionStorage.getItem(APP_CONSTANTS.user_properties);
        if (Util.isNullAndEmpty(value)) {
            return null
        }
        else {
            return JSON.parse(value)
        }
    }

    static getQueryParams() {
        let params = localStorage.getItem(APP_CONSTANTS.params)
        return params;
    }



    static setUserToken = (token) => {
        // if token is null , undefined or "" then not to set it.
        if (!Util.isNullAndEmpty(token)) {
            localStorage.setItem(APP_CONSTANTS.user_token, token)
        }
    }
    static getUserToken = () => {
        let token = localStorage.getItem(APP_CONSTANTS.user_token)
        if (Util.isNullAndEmpty(token) === false) {
            return null
        }
        else return token
    }



    static setPaginationPage = (obj) => {
        if (!Util.isNullAndEmpty(obj)) {
            sessionStorage.setItem(APP_CONSTANTS.page, obj)
            // sessionStorage
        }
    }
    static getPaginationPage= () => {
        let value = sessionStorage.getItem(APP_CONSTANTS.page)
        if (Util.isNullAndEmpty(value)) {
            return false
        }
        else {
            return JSON.parse(value)
        }
    }


    static setNavigator = (obj) => {
        // if token is null , undefined or "" then not to set it.
        if (!Util.isNullAndEmpty(obj)) {
            localStorage.setItem(APP_CONSTANTS.navigator, obj)
            // sessionStorage
        }
    }
    static getNavigator = () => {
        let navigator = localStorage.getItem(APP_CONSTANTS.navigator)
        if (Util.isNullAndEmpty(navigator)) {
            return false
        }
        else {
            return JSON.parse(navigator)
        }
    }
}