import React, { useEffect } from "react";
import "../../UI/css/carousel.css";
let carouseWebviewCSS;
function CarouselLoader() {
  useEffect(() => {
    carouseWebviewCSS = {
      "--carousel-card-width": "210px",
      "--carousel-card-height": "196px",
    };

    return () => {};
  }, []);

  return (
    <div className="social_feed_main">
      <div className="carousel_webview" style={carouseWebviewCSS}>
        <h1 className="carousel_title">
          आय 💵 बढ़ाने के लिए घर बैठे प्रशिक्षण करें
        </h1>
        <div className="card_loading_animation is-loading carousel_webview">
          <div className="carousel">
            <div className="card_loading_image carousel_card"></div>
            <div className="card_loading_image carousel_card"></div>
            <div className="card_loading_image carousel_card"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarouselLoader;
