import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import '../App.css';
import { ReactComponent as Network } from '../assets/footer/network.svg';
import { ReactComponent as Weather } from '../assets/footer/weather.svg';
import { ReactComponent as Mandi } from '../assets/footer/mandi.svg';
import { ReactComponent as Protection } from '../assets/footer/protection.svg';
import { ReactComponent as Crop } from '../assets/footer/crop.svg';
import { ReactComponent as Acrop } from '../assets/footer/a_crop.svg';
import { ReactComponent as Anetwork } from '../assets/footer/a_network.svg';
import { ReactComponent as Aweather } from '../assets/footer/a_weather.svg';
import { ReactComponent as Amandi } from '../assets/footer/a_mandi.svg';
import { ReactComponent as Aprotection } from '../assets/footer/a_protection.svg';
import Promo from '../assets/icon/The Krishi App/promo.jpg'
import { ReactComponent as Close } from '../assets/icon/The Krishi App/close1.svg';
import { ReactComponent as Download } from '../assets/icon/The Krishi App/download.svg';
import { ReactComponent as Tap } from '../assets/icon/The Krishi App/tap.svg';
import ReactGA from 'react-ga';
// import useQuery from '../useQuery';
// import strings from '../UI/Localization';
// import { foo } from "./firebase";
import { FirebaseUtility } from '../utils/FirebaseUtility';


const trackingId = "UA-107744869-1"


ReactGA.initialize(trackingId);


class Header extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            snack: 'snack_bar1',
            isDisconnected: false,
        }

        let pages = [
          "privacy-policy",
          "landingpage",
          "payment_status",
          "products",
          "show-merchent",
          "show-merchant",
          "equipment",
          "tractor",
          "micronutrient",
          "ad-sales",
        ];

        // ReactGA.event({
        //     category: "install",
        //     action: "app_install_opened",
        //   });
        // alert(this.props.location.pathname)
        let pathname = this.props.location.pathname.split('/')[1]
        console.log("dialog ", this.props.location.pathname, pathname, pages.includes(pathname))
        if (!pages.includes(pathname)) {
            this.showAccess()
        }


        // initializeApp(firebaseConfig)

        // const analytics = getAnalytics();



        // Initialize Firebase
        // const app = initializeApp(firebaseConfig);
        // const analytics = getAnalytics(app);

        FirebaseUtility.init()
          
           
        
        // logEvent(analytics, 'check124',{});

        // const analytics2 = firebase.analytics();
        // logEvent(analytics2, 'notification_received');



        // const query = useQuery();
        // const lang = query.get("lang") || "hi";

        // strings.setLanguage(lang)


    }

    // async componentDidMount() {
    //     console.log("experiment start")
    //     if (window.dataLayer) {
    //       await window.dataLayer.push({ event: "optimize.activate" });
    //     }
    //     this.intervalId = setInterval(() => {
    //       if (window.google_optimize !== undefined) {
    //         const variant = window.google_optimize.get('h4wm6sV5RTGj5QZrfGewjg');
    //         console.log(variant)
    //         this.setState({ variant });
    //         clearInterval(this.intervalId);
    //       }
    //     }, 100);
    //   }


    showAccess = () => {
        console.log("dialog showAccess Called")
        if (!localStorage.getItem("download_status")) {
            if (navigator.userAgent.indexOf('Android') !== -1) {
                if ((this.props.location.pathname !== '/privacy-policy')
                    && !(this.props.location.pathname.includes('landingpage'))
                    && !(this.props.location.pathname.includes('payment_status'))) {
                    setTimeout(() => this.setState({ snack: 'snack_bar' }, () => {
                        console.log("download_prompt")
                        ReactGA.event({
                            category: "install",
                            action: "app_install_opened",
                        })
                    }), 10000);
                }
            }
        }
        else if (localStorage.getItem("download_status") === 'cross') {
            console.log("cross initialized")
            console.log(localStorage.getItem("current_date"), new Date().getDate())
            if (parseInt(new Date().getDate()) - parseInt(localStorage.getItem("current_date")) <= 0) {
                console.log("cross activated")
            } else {
                console.log("cross deactivated")

                if (navigator.userAgent.indexOf('Android') !== -1) {
                    console.log(window.location.href)
                    if (!(window.location.href.includes("landingpage") && (this.props.location.pathname !== '/privacy-policy')
                        && !(this.props.location.pathname.includes('payment_status'))))
                        setTimeout(() => {
                            this.setState({ snack: 'snack_bar' }, () => {
                                console.log("app_install Prompt")

                            })
                            ReactGA.event({
                                category: "install",
                                action: "app_install_opened",
                            });
                        }, 10000);
                }
            }

        } else if (localStorage.getItem("download_status") === 'download') {
            console.log("downloaded clicked")

        }

    }

    cross() {
        console.log(new Date().getDate())
        localStorage.setItem("download_status", 'cross')
        localStorage.setItem("current_date", new Date().getDate())
        localStorage.setItem("current_month", new Date().getMonth())

        this.setState({ snack: 'snack_bar1' })
    }

    download = () => {
        console.log("apk download")
        this.setState({ snack: 'snack_bar1' })
        ReactGA.event({
            category: "install",
            action: "app_install_clicked",
        });
        localStorage.setItem("download_status", "download")
        window.open('https://play.google.com/store/apps/details?id=com.krishi.krishi&hl=en_IN', '_blank')
    }

    async componentDidMount() {





        this.handleConnectionChange();
        window.addEventListener('online', this.handleConnectionChange);
        window.addEventListener('offline', this.handleConnectionChange);
    }

    componentWillUnmount() {
        window.removeEventListener('online', this.handleConnectionChange);
        window.removeEventListener('offline', this.handleConnectionChange);
    }

    handleConnectionChange = () => {
        const condition = navigator.onLine ? 'online' : 'offline';
        // window.alert(condition)
        console.log(condition)
        if (condition === 'online') {
            this.setState({ isDisconnected: false }, () => {
                // window.location.reload()
            })
            //   const webPing = setInterval(
            //     () => {
            //       fetch('//google.com', {
            //         mode: 'no-cors',
            //         })
            //       .then(() => {
            //         this.setState({ isDisconnected: false }, () => {
            //           return clearInterval(webPing)
            //         });
            //       }).catch(() => this.setState({ isDisconnected: true }) )
            //     }, 2000);
            //   return;
        } else {
            this.setState({ isDisconnected: true });

        }
        console.log(this.state.isDisconnected)
    }

    render() {
        console.log(this.props.location.pathname)
        let a = '/weather';
        let b = '/';
        let c = '/crop/list';
        let d = '/mandi/list';
        let e = '/crop/protect';
        //let f = '/xyz';
        if (this.props.location.pathname === a || this.props.location.pathname === b || this.props.location.pathname === c || this.props.location.pathname === d || this.props.location.pathname === e || this.props.location.pathname.indexOf('weather/lat') !== -1) {
            // const { isDisconnected } = this.state;
            if (this.state.isDisconnected) {
                return (
                    <div style={{ minHeight: '100vh', display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center', backgroundColor: '#ffffff', position: 'absolute', width: '100%', zIndex: 10000, margin: 'auto' }}>
                        <p>आपका इंटरनेट चालू नहीं हैं ! <br />
                            कृपया जांच लें   </p>
                    </div>
                )
            }
            return (
                <div style={{ display: 'flex', alignItems: 'center', margin: 'auto', justifyContent: 'center', maxWidth: '600px' }}>
                    <div className={this.state.snack} id="install-app-snack">
                        <div className="snack_bar_info">
                            <div style={{ width: '10%' }} className="snack_img_div">
                                <img src={Promo} alt="Krishi Network" />
                            </div>
                            <div style={{ width: '80%' }} className="snack_img_div">
                                <p>  कृषि नेटवर्क भारतीय किसानो  का  #1 ऍप   <Tap /> </p>
                            </div>
                            <div onClick={() => this.cross()} style={{ width: '10%' }}>
                                <Close />
                            </div>
                        </div>


                        <div onClick={() => this.download()} className="snack_bar_download">

                            <p> <Download /> <label>  इंस्टॉल ऍप  </label>     </p>
                            {/* <label onClick={()=>this.setState({snack:'snack_bar1'})}></label> */}
                        </div>

                    </div>
                    <div className="navbar">

                        <Link to="/" className="active">
                            {b === this.props.location.pathname ? <Anetwork /> : <Network />}
                            <p style={{ color: b === this.props.location.pathname ? 'green' : 'grey' }}>नेटवर्क</p>
                        </Link>



                        <Link to="/weather">
                            {a === this.props.location.pathname ? <Aweather /> : <Weather />}
                            <p style={{ color: a === this.props.location.pathname ? 'green' : 'grey' }}>मौंसम</p>
                        </Link>



                        <Link to="/crop/list">
                            {c === this.props.location.pathname ? <Acrop /> : <Crop />}
                            <p style={{ color: c === this.props.location.pathname ? 'green' : 'grey' }}>फसलें</p>
                        </Link>




                        <Link to="/crop/protect">
                            {e === this.props.location.pathname ? <Aprotection /> : <Protection />}
                            <p style={{ color: e === this.props.location.pathname ? 'green' : 'grey', }}>फसल सुरक्षा</p>
                        </Link>

                        <Link to="/mandi/list">
                            {d === this.props.location.pathname ? <Amandi /> : <Mandi />}
                            <p style={{ color: d === this.props.location.pathname ? 'green' : 'grey' }}>मंडी</p>
                        </Link>

                    </div>
                </div>
            )
        }
        return (
            <div style={{ display: 'flex', alignItems: 'center', margin: 'auto', justifyContent: 'center', maxWidth: '600px' }}>
                <div className={this.state.snack}>
                    <div className="snack_bar_info">
                        <div style={{ width: '10%' }} className="snack_img_div">
                            <img src={Promo} alt="Krishi Network" />
                        </div>
                        <div style={{ width: '80%' }} className="snack_img_div">
                            <h1>  कृषि नेटवर्क भारतीय किसानो  का  #1 ऍप  <Tap /> </h1>
                        </div>
                        <div onClick={() => this.cross()} style={{ width: '10%' }}>
                            <Close />
                        </div>
                    </div>


                    <div onClick={() => this.download()} className="snack_bar_download">

                        <p> <Download /> <label>  इंस्टॉल ऍप  </label>     </p>
                        {/* <label onClick={()=>this.setState({snack:'snack_bar1'})}></label> */}
                    </div>

                </div>
            </div >
        );
    }
}
export default withRouter(Header)
