import React from "react";
import Axios, { axios } from 'axios';


let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support 
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
}

export default class TabChange extends React.Component {

    state = {
        actions: []
    }

    componentDidMount() {
        document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
    }

    handleVisibilityChange = () => {
        if (document[hidden]) {
            this.setState({ actions: [...this.state.actions, 'hide'] });
        } else {
            this.setState({ actions: [...this.state.actions, 'show'] });
            this.fetchPaymentStatus()
        }
    }

    fetchPaymentStatus() {
        let payment_id = "plink_I6Qs2JTpV4zyfz"
        let url = `https://api.razorpay.com/v1/payment_links/${payment_id}`
        console.log(url)
         Axios.get(url,{},{
            auth: {
                username: "rzp_test_Vn7viNbcVtjTtI",
                password: "ptiUDhXeu6ShK5mD4RUnb9kg"
              }
         })
            .then((response) => {
                // response.data.content.map((object) => {
                // this.setState({
                //     forms: this.state.forms.concat([object]),
                // }, function () {
                //     // console.log("data callback", this.state.forms);
                // })

                // })
                alert("Payment Status"+response.data.content.status)
            })
    }

    componentWillUnmount() {
        document.removeEventListener(visibilityChange, this.handleVisibilityChange);
    }

    render() {
        return (
            <ul>
                {
                    this.state.actions.map((item, key) => <li key={key}>{item}</li>)
                }
            </ul>
        )
    }
}
