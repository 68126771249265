import React from 'react';


function InitialLoader(props) {
    var boxStyle = {
        width: "100px",
        height: "100px",
        // color:""
    }
    return (
        <div style={{
            width: "100vw",
            height: "100vh",
            // background: "rgb(53, 126, 221)",
            paddingTop: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }} >
            <h1 style={{boxStyle}}>Loading</h1>
            {/* <img  style={{boxStyle}}  src={spinner}  width="100px" alt="" /> */}
        </div>
    );

}

export default InitialLoader;