import config from "../config";
import { APP_CONSTANTS } from "./constant";
import strings from "../UI/Localization";

class Util {
  static launched_languages = ["en"];

  // web_page_link is a Android JavaScriptInterface object to
  static showToast(message) {
    if (!Util.isAndroid()) {
      Util.informAndroidActions(message);
    } else {
      window.web_page_link.showToast(message);
    }
  }

  static handleOpenURL(url, id, courseName, tutorName) {
    if (!Util.isAndroid()) {
      Util.showToast(url);
      return true;
    } else {
      window.web_page_link.openUrl(url, id, courseName, tutorName);
    }
  }

  static isUpiCapable(isupi, appversion, testupi) {
    var request_upi = isupi && appversion >= 194;
    // alert("request_upi"+request_upi)
    // return (isupi && (appversion >= 200)) || (testupi !== true)
    return request_upi;
  }

  static openLink(payment_link) {
    window.open(payment_link) || window.location.replace(payment_link);
  }

  static cleanNumber = (number) => {
    let localNumber = number || "";
    console.log({ localNumber });
    localNumber = String(localNumber);
    if (localNumber.substring(0, 3) === "+91") {
      localNumber = localNumber.substring(3);
    } else if (
      localNumber.length > 10 &&
      localNumber.substring(0, 3) === " 91"
    ) {
      // "+" from query string becomes " "
      localNumber = localNumber.substring(3);
    } else if (
      localNumber.length > 10 &&
      localNumber.substring(0, 2) === "91"
    ) {
      localNumber = localNumber.substring(2);
    }
    return localNumber;
  };
  static formatINRPrice = (x) => {
    if (x != null && x.length > 0) {
      return x.toString().split(".")[0].length > 3
        ? x
            .toString()
            .substring(0, x.toString().split(".")[0].length - 3)
            .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
            "," +
            x.toString().substring(x.toString().split(".")[0].length - 3)
        : x.toString();
    } else {
      return "--";
    }
  };

  static formatINRPriceWithUnit = (price) => {
    if (!this.isEmpty(price)) {
      price = price.split(" ")[0];
      var val = Math.abs(price);
      if (val >= 10000000) {
        val = (val / 10000000).toFixed(1) + "* C +";
      } else if (val >= 100000) {
        val = (val / 100000).toFixed(1) + "* L +";
      } else if (val >= 1000) {
        val = (val / 1000).toFixed(1) + "* ";
      }
      // return " ₹ "+ val;
      return val;
    } else {
      return "";
    }
  };

  static isEmpty = (string) => {
    if (string != null && string.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  static stringToHTMl = (element) => {
    var doc = new DOMParser().parseFromString(element, "text/html");
    console.log(doc.firstChild.innerHTML); // => <a href="#">Link...
    console.log("final html", doc.firstChild.firstChild.innerHTML);
    return doc;
  };

  static cleanHTMLString = (element) => {
    // var doc = new DOMParser().parseFromString(element, "text/xml");
    // element+="<p></p>"
    element = element
      .replaceAll("<p>&nbsp;</p>", "")
      .replaceAll("&nbsp;", "")
      .trim()
      .replace(/<p>\s*<\/p>/gi, "")
      .replace(/<p\s*\/>/gi, "")
      .trim();
    console.log("element", element);
    var doc = new DOMParser().parseFromString(element, "text/html");
    console.log(doc.firstChild.innerHTML); // => <a href="#">Link...
    // console.log("final clean p tag of html",doc.innerHTML, doc.firstChild.innerHTML,doc.firstChild.childNodes[1].innerHTML, doc.firstChild.firstChild.innerHTML);
    console.log(
      "final clean p tag of html body",
      doc.firstChild.childNodes[1].innerHTML
    );
    let span = document.createElement("p");
    span.style.display = "inline";
    let bodyInner = doc.firstChild.childNodes[1];
    console.log("boddyInner", bodyInner.childNodes, bodyInner.innerHTML);
    if (bodyInner.length === 1) {
      span.innerHTML = bodyInner[0].innerHTML;
    } else {
      span.innerHTML = doc.firstChild.childNodes[1].innerHTML;
    }
    console.log("final clean p tag of html body", span);

    // span.insertAdjacentElement('afterbegin', doc.firstChild.childNodes[1].innerHTML);
    return new XMLSerializer().serializeToString(span);
    // return doc;
  };

  static goBackOrClose = () => {
    if (!Util.isAndroid()) {
      Util.informAndroidActions("goBackOrClose called");
    } else {
      // Util.showToast("goBackOrClose called web_page_link_found");
      window.web_page_link.closeWebView();
    }
  };

  static initiateAndroidAuth = (current_path) => {
    if (!Util.isAndroid()) {
      if (config.setting.debug) {
        Util.informAndroidActions("initiateAndroidAuth called");
      }
    } else {
      window.web_page_link.initiateAndroidAuth(current_path);
    }
  };

  /**
   * open any android event after
   * */
  static openAndroidIntent = (current_path, deeplink) => {
    if (!Util.isAndroid()) {
      Util.informAndroidActions("openAndroidIntent called");
    } else {
      window.web_page_link.openAndroidIntent(current_path, deeplink);
    }
  };

  /**
   * detect if android or normal webview
   * @returns {boolean}
   */
  static isAndroid = () => {
    // alert(window.web_page_link === undefined)
    return window.web_page_link !== undefined;
  };

  static informAndroidActions = (message) => {
    if (config.setting.debug) {
      alert(message);
    } else {
      console.log(message);
    }
  };

  static isNullAndEmpty = (object) => {
    if (
      object === undefined ||
      object === null ||
      (typeof object === "string" && object.length === 0)
    ) {
      return true;
    } else {
      return false;
    }
  };

  static createMarkup(html_string) {
    // return {__html: 'First &middot; Second'};
    return { __html: html_string };
  }

  static setLocalisationLanguage = (lang) => {
    if (Util.launched_languages.includes(lang)) {
      strings.setLanguage(lang);
    } else {
      strings.setLanguage(APP_CONSTANTS.default_products_lang);
    }
    let l = strings.getLanguage();
    console.log(
      "provided lang",
      lang,
      "setLanguage set localised language is ",
      l,
      strings.allow
    );
  };

  /***
   * Return the default_products_lang if disabled language id given
   */
  static getEnabledLanguage(lang) {
    if (Util.launched_languages.includes(lang) || config.setting.debug) {
      return lang;
    } else {
      return APP_CONSTANTS.default_products_lang;
    }
  }

  static getParameter = (key) => {
    // Address of the current window
    const address = window.location.search;
    // Returns a URLSearchParams object instance
    const parameterList = new URLSearchParams(address);

    // Returning the respected value associated
    // with the provided key
    return parameterList.get(key);
  };
}

export default Util;
