import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./Components/Header";
import Loadable from "react-loadable";
import PaymentTest from "./pages/PaymentTest";
import InitialLoader from "./Components/common/InitialLoader";
import Carousel from "./UI/components/Carousel";
import CarouselLoader from "./Components/common/CarouselLoader";
// import {BrowserHistory } from 'react-router'
// import { createBrowserHistory } from 'history'

const AsyncFeed = Loadable({
  loader: () => import("./Components/Feed"),
  loading() {
    return <InitialLoader />;
  },
});
const AsyncWeather = Loadable({
  loader: () => import("./Components/Weather"),
  loading() {
    return <InitialLoader />;
  },
});
const AsyncCrop_details = Loadable({
  loader: () => import("./Components/Crop_details"),
  loading() {
    return <InitialLoader />;
  },
});
const AsyncItemDetails = Loadable({
  loader: () => import("./Components/Item_details"),
  loading() {
    return <InitialLoader />;
  },
});
const AsyncWrappedNormalLoginForm = Loadable({
  loader: () => import("./Components/Authentication"),
  loading() {
    return <InitialLoader />;
  },
});

const AsyncTagFeed = Loadable({
  loader: () => import("./Components/Tag_feed"),
  loading() {
    return <InitialLoader />;
  },
});
const AsyncmarketDetails = Loadable({
  loader: () => import("./Components/crop_market_details"),
  loading() {
    return <InitialLoader />;
  },
});
const AsyncCrop_market = Loadable({
  loader: () => import("./Components/Crop_market"),
  loading() {
    return <InitialLoader />;
  },
});
// const AsyncAddCrops = asyncComponent(() => import("./Components/AddCrop"));
const AsyncAddCrops = Loadable({
  loader: () => import("./Components/AddCrop"),
  loading() {
    return <InitialLoader />;
  },
});
// const AsyncCrops = asyncComponent(() => import("./Components/Crops"));
const AsyncCrops = Loadable({
  loader: () => import("./Components/Crops"),
  loading() {
    return <InitialLoader />;
  },
});
// const AsyncProfile = asyncComponent(() => import("./Components/Profile"));
const AsyncProfile = Loadable({
  loader: () => import("./Components/Profile"),
  loading() {
    return <InitialLoader />;
  },
});
// const AsyncComments = asyncComponent(() => import("./Components/Comment"));
const AsyncComments = Loadable({
  loader: () => import("./Components/Comment"),
  loading() {
    return <InitialLoader />;
  },
});
// const AsyncHeader = asyncComponent(() => import("./Components/Header"));
// const AsyncHeader = Loadable({
// 	loader: () => import( './Components/Header' ),
// 	loading() {
// 		return <h1>Loading</h1>
// 	}
// });
// const AsyncVideoFeed = asyncComponent(() => import("./Components/Videofeed"));
const AsyncVideoFeed = Loadable({
  loader: () => import("./Components/Videofeed"),
  loading() {
    return <InitialLoader />;
  },
});
// const AsyncScheme = asyncComponent(() => import("./Components/GovtScheme"));
const AsyncScheme = Loadable({
  loader: () => import("./Components/GovtScheme"),
  loading() {
    return <InitialLoader />;
  },
});
// const Asyncschemedetails = asyncComponent(() => import("./Components/govtschemedetails"));
const Asyncschemedetails = Loadable({
  loader: () => import("./Components/govtschemedetails"),
  loading() {
    return <InitialLoader />;
  },
});
// const Asynccropsafty = asyncComponent(() => import("./Components/Crop_safety"));
const Asynccropsafty = Loadable({
  loader: () => import("./Components/Crop_safety"),
  loading() {
    return <InitialLoader />;
  },
});
// const AsyncCropdiseas = asyncComponent(() => import("./Components/cropdiseas"));
const AsyncCropdiseas = Loadable({
  loader: () => import("./Components/cropdiseas"),
  loading() {
    return <InitialLoader />;
  },
});
// const Asyncdiseasequery = asyncComponent(() => import("./Components/diseasequery"));
const Asyncdiseasequery = Loadable({
  loader: () => import("./Components/diseasequery"),
  loading() {
    return <InitialLoader />;
  },
});
// const AsynctestCrops = asyncComponent(() => import("./Components/testcrop"));
// const AsynctestCrops = Loadable({
// 	loader: () => import( './Components/testcrop' ),
// 	loading() {
// return <InitialLoader/>;;
// 	}
// });
// const Asyncqueryresolve = asyncComponent(() => import("./Components/queryresolve"));
const Asyncqueryresolve = Loadable({
  loader: () => import("./Components/queryresolve"),
  loading() {
    return <InitialLoader />;
  },
});
// const Asyncpesticidedetails = asyncComponent(() => import("./Components/pesticidedetails"));
// const AsyncHome = asyncComponent(() => import("./Components/Feed"));
const Asyncpesticidedetails = Loadable({
  loader: () => import("./Components/pesticidedetails"),
  loading() {
    return <InitialLoader />;
  },
});
// const AsyncPrivacy = asyncComponent(() => import("./Components/privacypolicy"));
const AsyncPrivacy = Loadable({
  loader: () => import("./Components/privacypolicy"),
  loading() {
    return <InitialLoader />;
  },
});

const AsyncTnC = Loadable({
  loader: () => import("./Components/termsAndCondition"),
  loading() {
    return <InitialLoader />;
  },
});

const AsyncRefund = Loadable({
  loader: () => import("./Components/refundpolicy"),
  loading() {
    return <InitialLoader />;
  },
});
// const AsyncImageItems = asyncComponent(() => import("./Components/Imageitems"));
const AsyncImageItems = Loadable({
  loader: () => import("./Components/Imageitems"),
  loading() {
    return <InitialLoader />;
  },
});
// const AsyncUserTechnology = asyncComponent(() => import("./Components/UserTechnology"));
const AsyncUserTechnology = Loadable({
  loader: () => import("./Components/UserTechnology"),
  loading() {
    return <InitialLoader />;
  },
});
// const AsyncUserFollower = asyncComponent(() => import("./Components/UserFollower"));
const AsyncUserFollower = Loadable({
  loader: () => import("./Components/UserFollower"),
  loading() {
    return <InitialLoader />;
  },
});
// const AsyncUserFollowing = asyncComponent(() => import("./Components/UserFollowing"));
const AsyncUserFollowing = Loadable({
  loader: () => import("./Components/UserFollowing"),
  loading() {
    return <InitialLoader />;
  },
});
// const AsyncAppinbox = asyncComponent(() => import("./Components/Appinbox"));
const AsyncAppinbox = Loadable({
  loader: () => import("./Components/Appinbox"),
  loading() {
    return <InitialLoader />;
  },
});
// const AsyncVisitors = asyncComponent(() => import("./Components/Visitors"));
const AsyncVisitors = Loadable({
  loader: () => import("./Components/Visitors"),
  loading() {
    return <InitialLoader />;
  },
});
// const AsyncBadgeEducation = asyncComponent(() => import("./Components/BadgesScreen"));
const AsyncBadgeEducation = Loadable({
  loader: () => import("./Components/BadgesScreen"),
  loading() {
    return <InitialLoader />;
  },
});
// const AsyncAllBadges = asyncComponent(() => import("./Components/AllBadges"));
const AsyncAllBadges = Loadable({
  loader: () => import("./Components/AllBadges"),
  loading() {
    return <InitialLoader />;
  },
});
// const AsyncPointHisory = asyncComponent(() => import("./Components/PointHistory"));
const AsyncPointHisory = Loadable({
  loader: () => import("./Components/PointHistory"),
  loading() {
    return <InitialLoader />;
  },
});
// const AsyncProfileitems = asyncComponent(() => import("./Components/Profileitems"));
const AsyncProfileitems = Loadable({
  loader: () => import("./Components/Profileitems"),
  loading() {
    return <InitialLoader />;
  },
});
// const AsyncComment_reply = asyncComponent(() => import("./Components/social_comment_reply"));
const AsyncComment_reply = Loadable({
  loader: () => import("./Components/social_comment_reply"),
  loading() {
    return <InitialLoader />;
  },
});
// const AsyncQuestion = asyncComponent(() => import("./Components/Question"));
const AsyncQuestion = Loadable({
  loader: () => import("./Components/Question"),
  loading() {
    return <InitialLoader />;
  },
});
// const AsyncQuestionans= asyncComponent(() => import("./Components/Questionans"));
const AsyncQuestionans = Loadable({
  loader: () => import("./Components/Questionans"),
  loading() {
    return <InitialLoader />;
  },
});
// const AsyncCropQuestion = asyncComponent(() => import("./Components/CropQuestion"));
const AsyncCropQuestion = Loadable({
  loader: () => import("./Components/CropQuestion"),
  loading() {
    return <InitialLoader />;
  },
});
// const AsyncCropAnswer= asyncComponent(() => import("./Components/Cropanswer"));
const AsyncCropAnswer = Loadable({
  loader: () => import("./Components/Cropanswer"),
  loading() {
    return <InitialLoader />;
  },
});
// const AsyncTopicFeed = asyncComponent(() => import("./Components/Topic_feed"));
const AsyncTopicFeed = Loadable({
  loader: () => import("./Components/Topic_feed"),
  loading() {
    return <InitialLoader />;
  },
});
// const AsyncError = asyncComponent(() => import("./Components/Error"));
const AsyncError = Loadable({
  loader: () => import("./Components/Error"),
  loading() {
    return <InitialLoader />;
  },
});
// const AsyncShareWeather = asyncComponent(() => import("./Components/Shared_weather"));
const AsyncShareWeather = Loadable({
  loader: () => import("./Components/Shared_weather"),
  loading() {
    return <InitialLoader />;
  },
});
// const AsyncPosting = asyncComponent(() => import("./Components/Posting"));
const AsyncPosting = Loadable({
  loader: () => import("./Components/Posting"),
  loading() {
    return <InitialLoader />;
  },
});

const AsyncLandingpage = Loadable({
  loader: () => import("./pages/LandingPage"),
  loading() {
    return <InitialLoader />;
  },
});

const AsyncPaymentStatus = Loadable({
  loader: () => import("./pages/PaymentStatus"),
  loading() {
    return <InitialLoader />;
  },
});
/*
  Products Feed Screen
 */
const AsyncProductFeed = Loadable({
  loader: () => import("./UI/screens/ProductsFeed"),
  loading() {
    return <InitialLoader />;
  },
});

// Sample Static Ui component for equipment
const AsyncEquipments = Loadable({
  loader: () => import("./UI/equipment/Equipment"),
  loading() {
    return <InitialLoader />;
  },
});

const AsyncProductList = Loadable({
  loader: () => import("./pages/ProductList"),
  loading() {
    return <InitialLoader />;
  },
});

const AsyncProductModule = Loadable({
  loader: () => import("./UI/equipment/ProductModule"),
  loading() {
    return <InitialLoader />;
  },
});

// Sample Static Ui component for Tractor
const AsyncTractor = Loadable({
  loader: () => import("./UI/tractorEquipment/Tractor"),
  loading() {
    return <InitialLoader />;
  },
});

// Sample Static Ui component for Nutrition
const AsyncNutrient = Loadable({
  loader: () => import("./UI/micronutrients/Micronutrient"),
  loading() {
    return <InitialLoader />;
  },
});

// Android like Page Not found Screen
const AsyncPageNotFound = Loadable({
  loader: () => import("./UI/screens/NotFound"),
  loading() {
    return <InitialLoader />;
  },
});

// Nearby Merchant Screen
const AsyncVendorList = Loadable({
  loader: () => import("./UI/screens/MerchantList"),
  loading() {
    return <InitialLoader />;
  },
});

const AsyncAdCarousel = Loadable({
  loader: () => import("./UI/components/Carousel"),
  // loader: () => import("./Components/common/CarouselLoader"),
  loading() {
    return <CarouselLoader />;
  },
});

const AsyncCarouselCustomizer = Loadable({
  loader: () => import("./UI/components/CarouselCustomizer"),
  loading() {
    return <InitialLoader />;
  },
});

export default class App extends React.Component {
  render() {
    return (
      <Router>
        <Header />

        <Switch>
          {/* <Route exact path="/main" component={Home} props={childProps}/>  */}{" "}
          <Route exact path="/" component={AsyncFeed} />{" "}
          {/* <Route exact path="/feed" component={AsyncMain}/> */}{" "}
          <Route path="/feed/crop/:url_str" component={AsyncTagFeed} />{" "}
          <Route path="/feed/topic/:url_str" component={AsyncTopicFeed} />
          <Route path="/products/:category" component={AsyncProductList} exact />
          <Route path="/products/:category/:id" component={AsyncProductModule} exact />
          <Route path="/products" component={AsyncProductFeed} />
          <Route path="/show-merchant" component={AsyncProductFeed} />
          {/* Testing compoent for caching in android app */}
          <Route path="/show-merchent" component={AsyncProductFeed} />
          <Route path="/equipment" component={AsyncTractor} />
          <Route path="/tractor" component={AsyncEquipments} />
          <Route path="/micronutrient" component={AsyncNutrient} />
          <Route path="/not-found" component={AsyncPageNotFound} />
          <Route path="/vendor-list" component={AsyncVendorList} />
          <Route exact path="/weather" component={AsyncWeather} />
          <Route
            exact
            path="/weather/lat=:latitude&lng=:longitude"
            component={AsyncShareWeather}
          />
          <Route exact path="/privacy-policy" component={AsyncPrivacy} />
          <Route exact path="/terms-and-condition" component={AsyncTnC} />
          <Route exact path="/refund-policy" component={AsyncRefund} />
          <Route exact path="/crop/list" component={AsyncCrops} />{" "}
          <Route exact path="/crop/protect" component={Asynccropsafty} />{" "}
          <Route exact path="/crop/follow" component={AsyncAddCrops} />{" "}
          <Route
            exact
            path="/crop/protect/select"
            component={AsyncCropdiseas}
          />{" "}
          <Route exact path="/crop/:url_str" component={AsyncCrop_details} />{" "}
          <Route
            exact
            path="/crop/:url_str/questions"
            component={AsyncCropQuestion}
          />{" "}
          <Route
            exact
            path="/crop/:url_str/question"
            component={AsyncCropQuestion}
          />{" "}
          <Route
            exact
            path="/crop/:url_str/answer"
            component={AsyncCropAnswer}
          />
          <Route
            exact
            path="/crop/disease/:url_str"
            component={Asyncdiseasequery}
          />
          <Route exact path="/article/:url_str" component={AsyncItemDetails} />{" "}
          <Route exact path="/login" component={AsyncWrappedNormalLoginForm} />
          <Route exact path="/mandi/list" component={AsyncCrop_market} />{" "}
          <Route exact path="/mandi/:url_str" component={AsyncmarketDetails} />
          {/* <Route exact path="/profile/me" component={MyProfile} /> */}{" "}
          <Route exact path="/profile/:url_str" component={AsyncProfile} />{" "}
          <Route
            exact
            path="/profile/:url_str/followers"
            component={AsyncUserFollower}
          />{" "}
          <Route
            exact
            path="/profile/:url_str/following"
            component={AsyncUserFollowing}
          />{" "}
          <Route
            exact
            path="/profile/:url_str/badge-education"
            component={AsyncBadgeEducation}
          />{" "}
          <Route
            exact
            path="/profile/:url_str/badges"
            component={AsyncAllBadges}
          />{" "}
          <Route
            exact
            path="/profile/:url_str/image"
            component={AsyncProfileitems}
          />{" "}
          <Route
            exact
            path="/profile/:url_str/appinbox"
            component={AsyncAppinbox}
          />
          <Route exact path="/comments/:url_str" component={AsyncComments} />{" "}
          <Route
            path="/comment/:url_str/reply"
            component={AsyncComment_reply}
          />
          <Route exact path="/video/:url_str" component={AsyncVideoFeed} />{" "}
          <Route exact path="/government-schemes" component={AsyncScheme} />
          <Route
            exact
            path="/governement-scheme-details"
            component={Asyncschemedetails}
          />
          <Route
            exact
            path="/diseasequery-resolve/:url_str"
            component={Asyncqueryresolve}
          />{" "}
          <Route
            exact
            path="/pesticide/:url_str"
            component={Asyncpesticidedetails}
          />{" "}
          <Route exact path="/image/:url_str" component={AsyncImageItems} />{" "}
          {/* <Route exact path="/profileimage/:url_str" component={Profileitems}/> */}
          <Route exact path="/visitors" component={AsyncVisitors} />
          <Route
            exact
            path="/technology/follow"
            component={AsyncUserTechnology}
          />{" "}
          <Route exact path="/appinbox" component={AsyncAppinbox} />{" "}
          <Route exact path="/point/history" component={AsyncPointHisory} />{" "}
          <Route exact path="/questions" component={AsyncQuestion} />{" "}
          <Route
            exact
            path="/question/:url_str/answer"
            component={AsyncQuestionans}
          />{" "}
          <Route exact path="/posting" component={AsyncPosting} />{" "}
          <Route exact path="/landingpage" component={AsyncLandingpage} />{" "}
          <Route exact path="/test_landingpage" component={AsyncLandingpage} />{" "}
          <Route exact path="/payment_status" component={AsyncPaymentStatus} />{" "}
          <Route exact path="/ad-sales/carousel" component={AsyncAdCarousel} />
          <Route
            exact
            path="/ad-sales/carousel/customize"
            component={AsyncCarouselCustomizer}
          />
          <Route path="/test_payments">
            <PaymentTest />
          </Route>
          <Route component={AsyncError} />{" "}
        </Switch>
      </Router>
    );
  }
}
