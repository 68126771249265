import LocalizedStrings from 'react-localization';

let strings = new LocalizedStrings({


    hi: {
        "categories": "श्रेणियाँ",
        "available_products": "उपलब्ध उत्पाद",
        "view_details": "विवरण देखें",
        "details": "विवरण",
        "buyBtn": "मुझे इसे खरीदने में रूचि है",
        "mrp": "एमआरपी मूल्य",
        "packaging_size": "पैकेजिंग का आकार",
        "see_more": "और देखे",
        "see_less": "कम देखे",
        "similar_products": "इसी तरह के उत्पादों",
        "zoom_in_to_check_image": "करीब से देखने के लिए ज़ूम इन करें",
        "product_features": "यह उपकरण से संभंधित जानकारी",
        "name": "नाम",
        "phone": "फोन नंबर",
        "deny": "मना करें",
        "allow": "अनुमति दें",
        "permission": "आपकी कुछ जानकारी देखना चाहते हैं",
        "filter": "फिल्टर",
        "apply_filter": "फ़िल्टर लागू करें",
        "remove_filter": "फिल्टर हटायें",
        "search_tractors_here": "अपनी ज़रूरत का ट्रेक्टर खोजें",
        "cancel": "रद्द करें",
        "produce_related": "उत्पाद सम्बंधित खरीद",
        "fetching_products": "आप तक जानकारी पोहोंच रही है!",
        "no_products_available": "आपके द्वारा खोजी गयी जानकारी उपलब्ध नहीं हैं",
        "interested":"इच्छुक",
        "specifications":"विशेषताएं",
        "tractor":"ट्रेक्टर",
        "tractor_related":"",
        "tractor_explorer":"ट्रेक्टर एक्स्प्लोरर",
        applied_filter:"फ़िल्टर लागू"

    },
    en: {
        categories: "Categories",
        available_products: "Results Found",
        view_details: "View details",
        details: "Details",
        buyBtn: "I'm interested in buying it",
        mrp: "MRP",
        packaging_size: "Packaging Size",
        see_more: "See More",
        see_less: "See Less",
        similar_products: "Similar Products",
        zoom_in_to_check_image: "Zoom in to see details",
        product_features: "Features",
        name: "Name",
        phone: "Phone",
        deny: "Deny",
        allow: "Allow",
        permission: "wants to access your information",
        filter: "Filter",
        apply_filter: "Apply Filter",
        remove_filter: "Remove Filters",
        search_tractors_here: "Find Tractor of your need",
        "cancel": "Cancel",
        "produce_related": "Products",
        "fetching_products": "Please wait while we fetch details",
        "no_products_available": "No Products Available",
        "interested":"Interested",
        "specifications":"Specifications",
        "tractor":"Tractor",
        "tractor_related":"",
        "tractor_details":"Tractor Details",
        "tractor_explorer":"Tractor Explorer",
        "applied_filter":"Applied Filters",
    }
});


// generate the language files. 
// initialize
// hi = {}
// en = {}

// final = {...source,...target}
// final will be a object in target language with missing keys in source

export default strings