// import { initializeApp } from 'firebase/app';
// import { getAnalytics , logEvent } from "firebase/analytics";

// const analytics = getAnalytics();
// logEvent(analytics, 'notification_received');


export const firebaseConfig = {
  apiKey: "AIzaSyBnl8J6430kZe1UNkJ7C-BxN-FRO-CgYdI",
  authDomain: "thekrishi-8136a.firebaseapp.com",
  databaseURL: "https://thekrishi-8136a.firebaseio.com",
  projectId: "thekrishi-8136a",
  storageBucket: "thekrishi-8136a.appspot.com",
  messagingSenderId: "558705319389",
  appId: "1:558705319389:web:dc506436813b7257b52a34",
  measurementId: "G-2W50QX245F"
  };
  
  // Initialize Firebase
  // window.firebase.initializeApp(firebaseConfig);
  
  
  // const remoteConfig = window.firebase.remoteConfig();
  // remoteConfig.settings = {
  //   minimumFetchIntervalMillis: 36000
  // };
  
  // console.log("remoteConfig is ", remoteConfig);
  
  // remoteConfig.defaultConfig = {
  //   somekey: "Default value for somekey -- did not come from firebase"
  // };
  
  // export const foo = "bar";
  
  // window.firebaseRemoteConfig = remoteConfig;
  
  // remoteConfig
  //   .fetchAndActivate()
  //   .then((response) => {
  //     console.log("response is ", response);
  //     var firebaseBrandedLeagueLogos = window.firebaseRemoteConfig.getValue(
  //       "Branded_League_Logos"
  //     );
  //     console.log(
  //       "firebaseBrandedLeagueLogos.asString() is ",
  //       firebaseBrandedLeagueLogos.asString()
  //     );
  //     var firebaseArray = JSON.parse(firebaseBrandedLeagueLogos.asString());
  //     window.firebaseBrandedLeagueLogosIconData = firebaseArray.reduce(function(
  //       acc,
  //       elem
  //     ) {
  //       acc[elem.league] = elem;
  //       return acc;
  //     },
  //     {});
  
  //     console.log(
  //       "window.firebaseBrandedLeagueLogosIconData is ",
  //       window.firebaseBrandedLeagueLogosIconData
  //     );
  //   })
  //   .catch(err => {
  //     console.log("fetch error!");
  //     console.error(err);
  //   });
  