import React from "react";

function CarouselCard(props) {
  return (
    <div
      className="carousel_card"
      onClick={() => {
        props.handleCardClick
          ? props.handleCardClick(props.product.url, props.product.id, props.product.courseName, props.product.tutorName)
          : console.log("no handleCardClick provided");
      }}
      title={props.product.name}
      tabindex="0"
    >
      <img src={props.product.img} alt="ad" />
      {props.product.name.length >= 20 ? (
        <marquee className="product_name">{props.product.name}</marquee>
      ) : (
        <p className="product_name">{props.product.name}</p>
      )}
    </div>
  );
}

export default CarouselCard;
