export const APP_CONSTANTS = {
    "ad_sales": "/api/adsales",
    "production_base_url": "https://thekrishi.com/v2",
    "tracking_id": "UA-107744869-1",
    "product_landing_page":"/landingpage?pid=DVLKEF&lang=hi&company=ट्रेक्टर कंपनियां&",
    "default_products_lang":"en",

    // filed constants
    "user_token": "user_token",
    "params":"params",
    "navigator":"navigator",
    "page":"page",
    "user_properties":"user_properties",
}

export const PAYMENT_STATUS_CONSTANT = {
    "CREATED": "created"
    , "AUTHORIZED": "authorized"
    , "CAPTURED": "captured"
    , "REFUNDED": "refunded"
    , "FAILED": "failed"
}