import React, { useState, useEffect } from "react";
import spinner from "../assets/spinner.gif";
import useQuery from "../useQuery";
import "../CSS/LandingPage.css"
import TabChange from "./TabChange";


const PaymentTest = () => {

  const query = useQuery();

  const plink = query.get("plink") || "https://rzp.io/i/3BdbaH4TV";
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // loadScript("https://checkout.razorpay.com/v1/checkout.js");
    loadScript("https://checkout.razorpay.com/v1/payment-button.js");
  }, []);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      var form = document.createElement("form")

      var script = document.createElement("script");
      script.src = src;
      script.dataset.payment_button_id = "pl_I5yiUwhVT4WWyn"
      script.dataset.order_id = "order_CgmcjRh9ti2lP7"
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      form.appendChild(script)
      document.getElementById("rzp-button").appendChild(form)
    });
  };

  // async function displayRazorpay() {
  //   const data = await fetch("http://localhost:1337/razorpay", {
  //     method: "POST",
  //   }).then((t) => t.json());

  //   console.log(data);

  //   const options = {
  //     key: process.env.RAZORPAY_KEY_ID,
  //     currency: data.currency,
  //     amount: data.amount,
  //     name: "Learn Code Online",
  //     description: "Wallet Transaction",
  //     image: "http://localhost:1337/logo.png",
  //     order_id: data.id,
  //     handler: function (response) {
  //       alert("PAYMENT ID ::" + response.razorpay_payment_id);
  //       alert("ORDER ID :: " + response.razorpay_order_id);
  //     },
  //     prefill: {
  //       name: "Anirudh Jwala",
  //       email: "anirudh@gmail.com",
  //       contact: "9999999999",
  //     },
  //   };

  //   const paymentObject = new window.Razorpay(options);
  //   paymentObject.open();
  // }
  // const razorpay = new Razorpay({
  //   key_id: process.env.RAZORPAY_KEY_ID,
  //   key_secret: process.env.RAZORPAY_KEY_SECRET,
  // });

  // createRazorPayOrder()
  // {
  //   const payment_capture = 1;
  //   const amount = 499;
  //   const currency = "INR";

  //   const options = {
  //     amount: amount * 100,
  //     currency,
  //     receipt: shortid.generate(),
  //     payment_capture,
  //   };

  //   try {
  //     const response = await razorpay.orders.create(options);
  //     console.log(response);
  //     res.json({
  //       id: response.id,
  //       currency: response.currency,
  //       amount: response.amount,
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }


  const paymentLinkClick = async (e) => {
    window.location.href = plink
  }

  const paymentLinkClickNewTab = async (e) => {
    window.open(plink) || window.location.replace(plink);
  }

  return isLoading ? (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "70%",
      }}
    >
      <img src={spinner} width="100px" alt="" />
    </div>
  ) : (
    <div>
      <p>{plink}</p>
      <div>
        <button onClick={paymentLinkClick} >Upi Link {plink}</button>
      </div>

      <div>
        <button onClick={paymentLinkClickNewTab} >Open in new tab {plink}</button>
      </div>
      <div>
        <p>
          {localStorage.getItem().ge}
        </p>
      </div>
      <div>
        <TabChange />
      </div>
      <div id="rzp-button">
      </div>
      {/* <form>
        <script src="https://checkout.razorpay.com/v1/payment-button.js" data-payment_button_id="pl_I5yiUwhVT4WWyn" async> </script> 
      </form> */}
      {/* <form><script src="https://checkout.razorpay.com/v1/payment-button.js" 
                  data-name="Acme Corp"
      data-payment_button_id="pl_I62tlWt9uZSKaf" async> </script> </form> */}

      {/* <button
          type="button"
          onClick={displayRazorpay}
          className="course-payment-button"
        >
          Buy Now
        </button> */}

      {/* <form action="https://www.example.com/payment/success/" method="POST">
        <script
            src="https://checkout.razorpay.com/v1/checkout.js"
            data-key="rzp_test_X18BRtuUMBAdce" // Enter the Test API Key ID generated from Dashboard → Settings → API Keys
            data-amount="29935" // Amount is in currency subunits. Hence, 29935 refers to 29935 paise or ₹299.35.
            data-currency="INR"// You can accept international payments by changing the currency code. Contact our Support Team to enable International for your account
            data-order_id="order_CgmcjRh9ti2lP7"// Replace with the order_id generated by you in the backend.
            data-buttontext="Pay with Razorpay"
            data-name="Acme Corp"
            data-description="A Wild Sheep Chase is the third novel by Japanese author Haruki Murakami"
            data-image="https://example.com/your_logo.jpg">
            </script>
            <Form.Group className="mb-3">
                  <Form.Control
                  type="hidden" custom="Hidden Element" name="hidden"
                  />
              </Form.Group>
        </form> */}
    </div>
  );
};

export default PaymentTest;
